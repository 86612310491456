import React, { PropsWithChildren } from 'react';
import { AlertSnackbar } from '@clippings/paper';
import { Chat, ChatVisibilityProvider } from 'libs/Chat';
import { Column, pageViewContainerResponsiveStyles } from 'libs/shared';
import { FooterSticky } from 'libs/shared';
import { QuoteFooterActionButtons } from '../QuotesFooter/QuoteFooterActionButtons';
import { QuoteFooterDetails } from '../QuotesFooter/QuoteFooterDetails';
import { QuoteItemProductValidationProvider } from 'libs/Quotes/providers/QuoteItemProductValidationProvider';
import { QuoteListItemStatus } from 'libs/api/quotes/types';
import { QuoteModals } from '../QuoteModals';
import { UserPermission } from 'libs/api/iam/types';
import { useAlertSnackbar } from 'libs/hooks/useAlertSnackbar';
import { useHasPermission } from 'libs/hooks/useHasPermission';
import { useQuoteProvider } from '../providers';
import './QuoteView.scss';

export const QuoteView = ({ children }: PropsWithChildren) => {
  const { quote } = useQuoteProvider();
  const { props: snackbarProps, handleShowSnackbar } = useAlertSnackbar();

  const isCustomerUser = !useHasPermission(UserPermission.CanAccessSalesApp);
  const withDownloadButton = quote.status !== QuoteListItemStatus.Draft && isCustomerUser;

  return (
    <QuoteItemProductValidationProvider quote={quote}>
      <Column
        height="100%"
        paddingTop={pageViewContainerResponsiveStyles.py}
        paddingBottom={0}
        px={pageViewContainerResponsiveStyles.px}
        className="quote-view"
      >
        <ChatVisibilityProvider>
          <Column flexGrow={1} gap={2} pb={2}>
            {children}
          </Column>
          <FooterSticky
            rightContent={<QuoteFooterActionButtons />}
            leftContent={<QuoteFooterDetails withDownloadButton={withDownloadButton} />}
          />
          <Chat />
        </ChatVisibilityProvider>

        <AlertSnackbar {...snackbarProps} />
        <QuoteModals handleShowSnackbar={handleShowSnackbar} />
      </Column>
    </QuoteItemProductValidationProvider>
  );
};
