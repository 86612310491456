import React, { useEffect, useState } from 'react';
import { Badge, EmailIcon } from '@clippings/paper';
import { useChatClient } from '../providers';

export const ChatBadge = () => {
  const { client } = useChatClient();
  const [unreadMessages, setUnreadMessages] = useState<number>(
    (client.user?.total_unread_count as number) ?? 0
  );

  useEffect(() => {
    client.on(() => {
      if (client.user?.total_unread_count !== unreadMessages) {
        setUnreadMessages((client.user?.total_unread_count as number) ?? 0);
      }
    });
  }, []);

  return (
    <Badge
      data-testid="chat-badge"
      badgeContent={unreadMessages}
      color="secondary"
      aria-label="comments"
    >
      <EmailIcon />
    </Badge>
  );
};
