import React, { PropsWithChildren, createContext, useContext } from 'react';
import { ProductErrors } from 'libs/SalesApp/Quotes/types';
import { Quote } from 'libs/api/quotes/types';
import { useQuery } from '@tanstack/react-query';
import { validateProducts } from 'libs/api/quotes/actions';

type QuoteItemProductValidationContextType = {
  errors: ProductErrors['invalidProperties'];
};

type QuoteItemProductValidationProviderProps = {
  quote: Quote;
};

const QuoteItemProductValidationContext = createContext(
  {} as QuoteItemProductValidationContextType
);

export function QuoteItemProductValidationProvider({
  quote,
  children,
}: PropsWithChildren<QuoteItemProductValidationProviderProps>) {
  const { data } = useQuery(['quote-item-product-validation', quote], () =>
    validateProducts(quote.versionId)
  );

  const errors = data?.invalidProperties ?? {};

  return (
    <QuoteItemProductValidationContext.Provider
      value={{
        errors,
      }}
    >
      {children}
    </QuoteItemProductValidationContext.Provider>
  );
}

export function useQuoteItemProductValidation() {
  const context = useContext(QuoteItemProductValidationContext);

  if (!context) {
    throw new Error(
      'useQuoteItemProductValidation must be used within a QuoteItemProductValidationProvider'
    );
  }

  return context;
}
