import React, { ComponentProps, useEffect, useRef, useState } from 'react';
import { Box, Button, Typography } from '@clippings/paper';
import { ConfiguredProduct } from '../../common/types';
import { MakeCustomConfirmationDialog } from '../../MakeCustomConfirmationDialog';
import { OrderProductItem } from 'libs/api/order/types';
import { QuoteItemHiddenDetails } from 'libs/Quotes/hooks/useQuoteHiddenDetailProducts';
import { QuoteItemProperty, QuoteProductItem } from 'libs/api/quotes/types';
import { VendorSku } from './VendorSku';
import { useItemFormattedProperties } from 'libs/shared';
import { useTranslation } from 'react-i18next';

type ProductInfoProps = {
  item: QuoteProductItem | OrderProductItem;
  textOnly?: boolean;
  loading?: boolean;
  readonly: boolean;
  detailsHidden: boolean;
  handleMakeCustom?: () => void;
  onConfigure?: (item: ConfiguredProduct) => void;
  onProductDetailsHide: (item: QuoteItemHiddenDetails) => void;
  invalidProperties?: string[];
};

export const getHighestPriceGroupName = (properties: QuoteItemProperty[] | null): string => {
  const sortedProperties = [...(properties ?? [])].sort(
    (a, b) => (b.priceGroupWeight ?? 0) - (a.priceGroupWeight ?? 0)
  );

  return sortedProperties[0]?.priceGroupName ?? '';
};

export const ProductInfo: React.FC<ProductInfoProps> = ({
  item,
  readonly,
  loading,
  textOnly = false,
  detailsHidden,
  onConfigure,
  handleMakeCustom,
  onProductDetailsHide,
  invalidProperties,
}) => {
  const [makeCustomConfirmModalOpen, setMakeCustomConfirmModalOpen] = React.useState(false);

  const propertiesContainerRef = useRef<HTMLDivElement>();
  const { t } = useTranslation();
  const { properties, FormattedPropertiesRenderer } = useItemFormattedProperties(
    item.properties ?? []
  );
  const [height, setHeight] = useState<string | undefined>();

  useEffect(() => {
    if (propertiesContainerRef.current) {
      const contentHeight = propertiesContainerRef.current.scrollHeight;
      setHeight(detailsHidden ? '0px' : `${contentHeight}px`);
    }
  }, [detailsHidden]);

  const handleConfigure = () => {
    if (!item.productId) {
      return;
    }

    onConfigure?.({
      productId: item.productId ?? '',
      productName: item.productName ?? '',
      existingSelection: item.sku ?? '',
      existingQuantity: item.quantity,
      quoteItemId: item.id,
      showGroupedProducts: true,
      isExisting: !!item.sku,
    });
  };

  const onProductItemDetailsToggle = () =>
    onProductDetailsHide({ id: item.id, hidden: !detailsHidden });

  const handleDialogMakeCustom = () => {
    handleMakeCustom?.();
    setMakeCustomConfirmModalOpen(false);
  };

  const highestPriceGroupName = getHighestPriceGroupName(item.properties);

  return (
    <>
      {handleMakeCustom && (
        <MakeCustomConfirmationDialog
          open={makeCustomConfirmModalOpen}
          onClose={() => setMakeCustomConfirmModalOpen(false)}
          onMakeCustom={handleDialogMakeCustom}
        />
      )}
      <Box
        sx={{
          backgroundColor: 'grey.A100',
          borderRadius: 1,
          position: 'relative',
          p: 1,
          '&:hover': {
            '.product-info__buttons': {
              visibility: 'visible',
            },
          },
        }}
        className="item-row__product-info"
      >
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography
            variant="body1"
            fontWeight="fontWeightMedium"
            data-testid={`quote-item-product-name-${item.id}`}
          >
            {item.productName}
          </Typography>
          {highestPriceGroupName && (
            <Typography
              data-testid="highest-price-group-name"
              sx={{
                backgroundColor: theme => `${theme.palette.secondary.main}22`,
                color: theme => theme.palette.secondary.main,
                borderRadius: 1,
                px: 0.5,
                ml: 2,
              }}
              fontSize={theme => theme.typography.pxToRem(13)}
            >
              {highestPriceGroupName}
            </Typography>
          )}
          <Box
            className="product-info__buttons"
            display="flex"
            visibility="hidden"
            gap={0.5}
            position="absolute"
            right="5px"
            top="5px"
          >
            <Button
              variant="outlined"
              color="secondary"
              sx={buttonStyles}
              onClick={onProductItemDetailsToggle}
              disabled={loading}
              data-testid={`${detailsHidden ? 'show-details' : 'hide-details'}-${item.sku}`}
            >
              {t(`${detailsHidden ? 'common.showDetails' : 'common.hideDetails'}`)}
            </Button>
            {!readonly && (
              <>
                {handleMakeCustom && (
                  <Button
                    variant="outlined"
                    color="secondary"
                    sx={buttonStyles}
                    onClick={() => setMakeCustomConfirmModalOpen(true)}
                    disabled={loading}
                    data-testid={`make-custom-${item.sku}`}
                  >
                    {t('common.makeCustom')}
                  </Button>
                )}
                {handleConfigure && (
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={handleConfigure}
                    data-testid={`configure-${item.sku}`}
                    sx={buttonStyles}
                    disabled={loading}
                  >
                    {t('common.configure')}
                  </Button>
                )}
              </>
            )}
          </Box>
        </Box>
        <VendorSku vendorSku={item.vendorSku} />
        <Box
          data-testid={`quote-item-product-properties-${item.id}`}
          ref={propertiesContainerRef}
          sx={{
            transition: 'height 0.2s ease-out',
            overflow: 'hidden',
            height,
          }}
        >
          <FormattedPropertiesRenderer
            properties={properties}
            textOnly={textOnly}
            invalidProperties={invalidProperties}
          />
        </Box>
      </Box>
    </>
  );
};

const buttonStyles: ComponentProps<typeof Button>['sx'] = {
  backgroundColor: 'common.white',
  '&:hover': {
    backgroundColor: 'common.white',
  },
};
