import React, { FC } from 'react';
import { BaseUser } from 'libs/api/common/types';
import { IconButtonProps } from '@clippings/paper';

export interface ChatButtonProps {
  onClick: () => void;
  iconButtonProps?: IconButtonProps;
  quoteSalesRep?: BaseUser;
  iconOnly?: boolean;
}

export const ChatButton: FC<ChatButtonProps> = () => {
  //TODO: Implement ChatButton component. This is temporary to preserve page layout
  return <div />;
};
