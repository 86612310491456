import React from 'react';
import { Column } from 'libs/shared';
import { CustomerApplicationsSection } from 'libs/SalesApp/Inbox/CustomerApplicationsSection';
import { FlexColumn, Typography } from '@clippings/paper';
import { QuoteRequestSection } from 'libs/SalesApp/Inbox/QuoteRequestSection';
import { useTranslation } from 'react-i18next';

export const InboxApp = () => {
  const { t } = useTranslation();
  return (
    <Column gap={4} p={{ xs: 2, md: 3 }}>
      <Typography variant="h5">{t('inbox.inbox')}</Typography>
      <FlexColumn>
        <QuoteRequestSection />
        <CustomerApplicationsSection />
      </FlexColumn>
    </Column>
  );
};
