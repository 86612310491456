import React, { useState } from 'react';
import { Catalogue } from 'libs/api/iam/types';
import { ChipMenu, MenuItem, SellIcon } from '@clippings/paper';
import { Readonly } from 'libs/shared';
import { menuStyle } from 'libs/Quotes/QuoteActionsStack';
import { useQuoteCatalogueSelect } from './hooks/useQuoteCatalogueSelect';
import { useTranslation } from 'react-i18next';

interface CatalogueSelectProps extends Readonly {
  slug?: string;
  companyId?: number;
  disabled: boolean;
  onSelect: (slug: string) => void;
  onClick?: () => void;
}

export const CatalogueSelect: React.FC<CatalogueSelectProps> = ({
  slug,
  disabled,
  companyId,
  readonly = false,
  onSelect,
  onClick,
}) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  const { selectedCatalogue, availableCatalogues, isDisabled } = useQuoteCatalogueSelect(
    companyId,
    slug
  );
  const handleCatalogueChange = (catalogue: Catalogue) => {
    setIsOpen(false);
    onSelect(catalogue.slug);
  };
  const onToggle = () => {
    setIsOpen(!isOpen);
    onClick?.();
  };

  return (
    <ChipMenu
      adornment={<SellIcon key="catalogue" color="secondary" />}
      placeholder={t('common.catalogue')}
      value={selectedCatalogue?.name ?? slug}
      label={t('common.catalogue')}
      hasArrow={readonly && !slug}
      onToggle={onToggle}
      disabled={readonly || (isDisabled && disabled) || availableCatalogues.length === 0}
      isOpen={isOpen}
      data-testid="change-catalogue"
      sx={menuStyle}
    >
      {availableCatalogues.map(catalogue => (
        <MenuItem onClick={() => handleCatalogueChange(catalogue)} key={catalogue.id}>
          {catalogue.name}
        </MenuItem>
      ))}
    </ChipMenu>
  );
};
