import React, { useState } from 'react';
import { Chip, ExpandMoreIcon, Typography } from '@clippings/paper';
import { MAX_CHIP_PROPERTY_LENGTH } from '../../Utils';
import './PropertyRow.scss';

export enum PropertyType {
  Text = 'text',
  Chip = 'chip',
}

export type PropertyRowProps = {
  property: {
    value: string;
    isPriceGroup: boolean;
    code: string;
  };
  initialType: PropertyType;
  renderPropertyCode?: boolean;
  invalid?: boolean;
};

export const PropertyRow: React.FC<PropertyRowProps> = ({
  property,
  initialType,
  renderPropertyCode = false,
  invalid = false,
}) => {
  const { value, isPriceGroup, code } = property;
  const [type, setType] = useState<PropertyType>(initialType);
  const isTooLong = value.length > MAX_CHIP_PROPERTY_LENGTH;

  const handleClick = () => {
    setType(PropertyType.Text);
  };

  const handler = isTooLong ? handleClick : undefined;

  if (type === PropertyType.Chip) {
    const label = `${renderPropertyCode ? `${code} ` : ''}${
      value.substring(0, MAX_CHIP_PROPERTY_LENGTH) + (isTooLong ? '...' : '')
    }`;

    return (
      <Chip
        data-testid="property-chip"
        label={label}
        deleteIcon={<ExpandMoreIcon />}
        sx={{ backgroundColor: 'white', m: 0.5 }}
        key={value}
        onDelete={handler}
        onClick={handler}
        className={isPriceGroup || invalid ? 'price-group' : undefined}
      />
    );
  }

  return (
    <Typography variant="caption" display="inline" ml={1} data-testid="property-text">
      {renderPropertyCode ? `${code}: ` : ''}
      {value}
    </Typography>
  );
};
