import React, { ComponentProps } from 'react';
import { Brand, CheckCircleIcon, Chip, ErrorIcon, Typography } from '@clippings/paper';
import { OrderItem, OrderStatus } from 'libs/api/order/types';

const iconStyles = {
  fontSize: 16,
};

export const ReadyIcon = <CheckCircleIcon color="success" sx={iconStyles} />;
export const WarningIcon = <ErrorIcon color="warning" sx={iconStyles} />;

type LabelKey =
  | 'orders.statuses.ordered'
  | 'orders.statuses.timeline.orderPlaced'
  | 'orders.statuses.partConfirmed'
  | 'orders.statuses.timeline.partConfirmed'
  | 'orders.statuses.confirmed'
  | 'orders.statuses.timeline.paymentConfirmed'
  | 'orders.statuses.orderInProduction'
  | 'orders.statuses.info.orderInProduction'
  | 'orders.statuses.timeline.orderInProduction'
  | 'orders.statuses.partShipped'
  | 'orders.statuses.timeline.partShipped'
  | 'orders.statuses.shipped'
  | 'orders.statuses.timeline.shipped'
  | 'orders.statuses.partDelivered'
  | 'orders.statuses.timeline.partDelivered'
  | 'orders.statuses.cancelled'
  | 'orders.statuses.timeline.cancelled'
  | 'orders.statuses.paid'
  | 'orders.statuses.timeline.paid'
  | 'orders.statuses.delivered'
  | 'orders.statuses.timeline.delivered'
  | 'orders.statuses.received'
  | 'orders.statuses.timeline.received'
  | 'orders.statuses.released'
  | 'orders.statuses.timeline.released'
  | 'orders.statuses.readyToBeShipped'
  | 'orders.statuses.timeline.readyToBeShipped';

type ChipProps = {
  labelKey: LabelKey;
} & Pick<ComponentProps<typeof Chip>, 'color' | 'variant' | 'sx'>;
type TimeLine = {
  labelKey: LabelKey;
  info?: {
    labelKey: 'orders.statuses.info.awaitingPayment' | 'orders.statuses.info.orderInProduction';
  } & Pick<ComponentProps<typeof Typography>, 'color'>;
  icon: JSX.Element;
};
export type OrderStatusState = {
  chip: ChipProps;
  timeline: TimeLine;
};
export type OrderStatusMap = {
  [Status in OrderStatus]: OrderStatusState;
};

const brandSpecificOrderStatusMap: { [BrandName in Brand]: Partial<OrderStatusMap> } = {
  [Brand.Fomcore]: {
    [OrderStatus.Ordered]: {
      chip: {
        labelKey: 'orders.statuses.ordered',
        color: 'success',
        variant: 'filled',
      },
      timeline: {
        labelKey: 'orders.statuses.timeline.orderPlaced',
        icon: ReadyIcon,
      },
    },
  },
  [Brand.Moroso]: {
    [OrderStatus.Confirmed]: {
      chip: {
        labelKey: 'orders.statuses.confirmed',
        color: 'warning',
        variant: 'filled',
      },
      timeline: {
        labelKey: 'orders.statuses.timeline.paymentConfirmed',
        info: {
          labelKey: 'orders.statuses.info.orderInProduction',
          color: 'secondary.main',
        },
        icon: ReadyIcon,
      },
    },
  },
  [Brand.Sossego]: {},
};

export const defaultOrderStatusMap: OrderStatusMap = {
  [OrderStatus.Ordered]: {
    chip: {
      labelKey: 'orders.statuses.ordered',
      color: 'success',
      variant: 'filled',
    },
    timeline: {
      labelKey: 'orders.statuses.timeline.orderPlaced',
      info: {
        labelKey: 'orders.statuses.info.awaitingPayment',
        color: 'warning.main',
      },
      icon: WarningIcon,
    },
  },
  [OrderStatus.PartConfirmed]: {
    chip: {
      labelKey: 'orders.statuses.partConfirmed',
      color: 'info',
      variant: 'filled',
    },
    timeline: {
      labelKey: 'orders.statuses.timeline.partConfirmed',
      icon: ReadyIcon,
    },
  },
  [OrderStatus.Confirmed]: {
    chip: {
      labelKey: 'orders.statuses.confirmed',
      color: 'info',
      variant: 'filled',
    },
    timeline: {
      labelKey: 'orders.statuses.timeline.paymentConfirmed',
      info: {
        labelKey: 'orders.statuses.info.orderInProduction',
        color: 'secondary.main',
      },
      icon: ReadyIcon,
    },
  },
  [OrderStatus.InProduction]: {
    chip: {
      labelKey: 'orders.statuses.orderInProduction',
      color: 'info',
      variant: 'filled',
    },
    timeline: {
      labelKey: 'orders.statuses.timeline.orderInProduction',
      icon: ReadyIcon,
    },
  },
  [OrderStatus.PartShipped]: {
    chip: {
      labelKey: 'orders.statuses.partShipped',
      color: 'secondary',
      variant: 'filled',
    },
    timeline: {
      labelKey: 'orders.statuses.timeline.partShipped',
      icon: ReadyIcon,
    },
  },
  [OrderStatus.Shipped]: {
    chip: {
      labelKey: 'orders.statuses.shipped',
      color: 'secondary',
      variant: 'filled',
    },
    timeline: {
      labelKey: 'orders.statuses.timeline.shipped',
      icon: ReadyIcon,
    },
  },
  [OrderStatus.PartDelivered]: {
    chip: {
      labelKey: 'orders.statuses.partDelivered',
      color: 'secondary',
      variant: 'filled',
      sx: {
        bgcolor: '#673AB7',
      },
    },
    timeline: {
      labelKey: 'orders.statuses.timeline.partDelivered',
      icon: ReadyIcon,
    },
  },
  [OrderStatus.Delivered]: {
    chip: {
      labelKey: 'orders.statuses.delivered',
      color: 'secondary',
      variant: 'filled',
      sx: {
        bgcolor: '#673AB7',
      },
    },
    timeline: {
      labelKey: 'orders.statuses.timeline.delivered',
      icon: ReadyIcon,
    },
  },
  [OrderStatus.Paid]: {
    chip: {
      labelKey: 'orders.statuses.paid',
      color: 'secondary',
      variant: 'filled',
      sx: {
        bgcolor: '#673AB7',
      },
    },
    timeline: {
      labelKey: 'orders.statuses.timeline.paid',
      icon: ReadyIcon,
    },
  },
  // Moroso-specific
  [OrderStatus.Received]: {
    chip: {
      labelKey: 'orders.statuses.received',
      color: 'success',
      variant: 'filled',
    },
    timeline: {
      labelKey: 'orders.statuses.timeline.received',
      icon: ReadyIcon,
    },
  },
  // Moroso-specific
  [OrderStatus.Released]: {
    chip: {
      labelKey: 'orders.statuses.released',
      color: 'info',
      variant: 'filled',
    },
    timeline: {
      labelKey: 'orders.statuses.timeline.released',
      icon: ReadyIcon,
    },
  },
  // Moroso-specific
  [OrderStatus.ReadyToBeShipped]: {
    chip: {
      labelKey: 'orders.statuses.readyToBeShipped',
      color: 'secondary',
      variant: 'filled',
      sx: {
        bgcolor: '#673AB7',
      },
    },
    timeline: {
      labelKey: 'orders.statuses.timeline.readyToBeShipped',
      icon: ReadyIcon,
    },
  },
  [OrderStatus.Cancelled]: {
    chip: {
      labelKey: 'orders.statuses.cancelled',
      color: 'error',
      variant: 'filled',
    },
    timeline: {
      labelKey: 'orders.statuses.timeline.cancelled',
      icon: ReadyIcon,
    },
  },
} as const;

export const brandOrderStatusMap: { [BrandName in Brand]: OrderStatusMap } = Object.entries(
  brandSpecificOrderStatusMap
).reduce((acc, [brand, statusMap]) => {
  acc[brand as Brand] = { ...defaultOrderStatusMap, ...statusMap };
  return acc;
}, {} as { [BrandName in Brand]: OrderStatusMap });

export function getOrderIdLabel(id: OrderItem['vendorId']): string {
  return id ? id : 'N/A';
}
