import React from 'react';
import { ErrorContext, QuoteTransitionErrorsEnum } from 'libs/SalesApp/Quotes/types';
import { Quote } from 'libs/api/quotes/types';
import { Typography } from '@clippings/paper';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { validateTransition } from 'libs/api/quotes/actions';

export interface FormattedErrorContext {
  positions: string;
}

export const useQuoteTransitionAction = (
  quote: Quote,
  transition: string,
  specialErrors: QuoteTransitionErrorsEnum[]
) => {
  const { t } = useTranslation();
  const query = useQuery(
    [
      quote.id,
      quote.quoteItemProducts,
      quote.shippingAddress,
      quote.billingAddress,
      quote.metadata,
      quote.status,
      quote.deliveryItems,
      quote.versionId,
      transition,
    ],
    () => {
      return validateTransition(quote.versionId, transition);
    }
  );

  const shouldDisableTransitionButton =
    !query.data?.every(err => specialErrors.includes(err.error)) || query.isLoading;

  const getBlockingErrors = () => {
    if (!query.data) {
      return [];
    }
    return query.data?.filter(err => !specialErrors.includes(err.error));
  };

  const buildBlockingErrorMessages = () => {
    return getBlockingErrors().map(err => {
      return (
        <Typography variant="subtitle2" key={err.error}>
          {t(`quotes.quoteBlockingErrors.${err.error}`, formatContext(err.context) as any)}
        </Typography>
      );
    });
  };

  const formatContext = (context: ErrorContext): FormattedErrorContext => {
    const formattedContext = {
      positions: '',
      catalogue: '',
    };
    if (context?.positions) {
      formattedContext.positions = context.positions.map(pos => pos + 1).join(', ');
    }

    if (context?.catalogue) {
      formattedContext.catalogue = context.catalogue;
    }

    return formattedContext;
  };

  return { errors: query.data || [], shouldDisableTransitionButton, buildBlockingErrorMessages };
};
