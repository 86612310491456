import {
  DecodedValueMap,
  NumericArrayParam,
  createEnumArrayParam,
  createEnumParam,
  withDefault,
} from 'use-query-params';
import { QuoteListItemStatus, QuoteProductItem } from 'libs/api/quotes/types';
import { SortDirection } from 'libs/api/common/types';

export enum QuoteMutation {
  Archive = 'archive',
  Restore = 'restore',
  Duplicate = 'duplicate',
  Update = 'update',
}

// query params
export enum QuotesTableSortParam {
  Sort = 'sort',
  Order = 'order',
}
export enum QuotesTableFilter {
  Assignee = 'assignee',
  Status = 'status',
  TeamsOfUser = 'teamsOfUser',
}
export enum QuotesTableColumn {
  RecentActivity = 'recentActivity',
  Quote = 'quote',
  Customer = 'customer',
  Value = 'value',
  Status = 'status',
  Assignee = 'assignee',
}
export const TABLE_COLUMNS = Object.values(QuotesTableColumn);
export const QUOTES_TABLE_SORT_DIRECTIONS: SortDirection[] = ['asc', 'desc'];

export const QUOTES_QUERY_PARAMS_MAP = {
  [QuotesTableFilter.Assignee]: withDefault(NumericArrayParam, undefined),
  [QuotesTableFilter.TeamsOfUser]: withDefault(NumericArrayParam, undefined),
  [QuotesTableFilter.Status]: withDefault(
    createEnumArrayParam(Object.values(QuoteListItemStatus)),
    undefined
  ),
  [QuotesTableSortParam.Order]: withDefault(createEnumParam(QUOTES_TABLE_SORT_DIRECTIONS), 'desc'),
  [QuotesTableSortParam.Sort]: withDefault(
    createEnumParam(TABLE_COLUMNS),
    QuotesTableColumn.RecentActivity
  ),
};
export type QuotesQueryParams = Partial<DecodedValueMap<typeof QUOTES_QUERY_PARAMS_MAP>>;
export type QuotesQueryParamsKeys = keyof QuotesQueryParams;
export type QuotesQueryParamsValues = QuotesQueryParams[QuotesQueryParamsKeys];

export enum QuotesPage {
  Active = 'active',
  Expired = 'expired',
  Archived = 'archived',
}

// filters
export type QuotesFilters = Pick<QuotesQueryParams, QuotesTableFilter>;
export const FILTER_PARAMS = Object.values(QuotesTableFilter);
export const CLEAN_FILTER_PARAMS = FILTER_PARAMS.reduce((clearObj, curr) => {
  clearObj[curr] = [];
  return clearObj;
}, {} as QuotesFilters);
export const QUOTES_PAGE_STATUS_MAP: Record<QuotesPage, QuoteListItemStatus[]> = {
  active: [
    QuoteListItemStatus.Draft,
    QuoteListItemStatus.Approved,
    QuoteListItemStatus.Ready,
    QuoteListItemStatus.Requested,
  ],
  expired: [QuoteListItemStatus.Expired],
  archived: [QuoteListItemStatus.Ordered, QuoteListItemStatus.Archived],
};
export const QUOTES_STATUS_PAGE_MAP = Object.keys(QUOTES_PAGE_STATUS_MAP).reduce((result, key) => {
  const statuses = QUOTES_PAGE_STATUS_MAP[key as QuotesPage];
  statuses.forEach(status => {
    result[status] = key as QuotesPage;
  });
  return result;
}, {} as Record<QuoteListItemStatus, QuotesPage>);

// sorting
export type QuotesTableSort = Pick<QuotesQueryParams, QuotesTableSortParam>;
export const SORT_PARAMS = Object.values(QuotesTableSortParam);

export const defaultSort: QuotesTableSort = {
  sort: QuotesTableColumn.RecentActivity,
  order: 'desc',
};
export const QUOTES_TABLE_COLUMNS_COUNT = TABLE_COLUMNS.length;

export type UpdateQuoteByKey = <K extends keyof QuoteProductItem>(
  key: K,
  value: QuoteProductItem[K] | null,
  itemId: number
) => void;

export enum QuoteTransitionErrorsEnum {
  MissingShippingAddress = 'missing_shipping_address',
  MissingBillingAddress = 'missing_billing_address',
  MissingQuoteItemProducts = 'missing_quote_item_products',
  MissingMetadata = 'missing_metadata',
  MissingStatus = 'missing_status',
  IncompleteItems = 'incomplete_items',
  InvalidShippingAddress = 'invalid_shipping_address',
}

export interface ErrorContext {
  positions?: number[];
  catalogue?: string;
}

export type QuoteTransitionError = {
  error: QuoteTransitionErrorsEnum;
  context: ErrorContext;
};

export type ProductErrors = {
  invalidProperties: Record<number, string[]>;
};
